<template>
    <el-dialog
        :title="mode == 'add' ? '新增最受欢迎数据' : '编辑最受欢迎数据'"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" class="demo-ruleForm">
            <el-form-item label="工具" prop="id">
                <el-select v-model="form.id" placeholder="请选择工具" :disabled="mode!='add'">
                    <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="排序" prop="seq">
                <el-input-number v-model="form.seq" :min="1" size="medium"></el-input-number>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="medium" @click="save">保存</el-button>
            <el-button size="medium" @click="closeDialog">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props:{
        vkey: {
            type: String
        }
    },
    data() {
        return {
            dialogVisible: false,
            mode: 'add',
            form: {
               id: '',
               seq: 1
            },
            rules: {
                id: [
                    { required: true, message: '请选择工具', trigger: 'change' }
                ],
                seq: [
                    { required: true, message: '请选择排序序号', trigger: 'change' }
                ]
            },
            options: []
        };
    },
    methods: {
        show(type, row) {
            this.dialogVisible = true;
            this.mode = type;
            if(type == 'add') {
                this.form = {
                    id: '',
                    seq: 1
                }
            } else {
                this.form = {
                    id: row.id,
                    seq: row.seq
                }
            }
            this.getTool();
            this.$refs['form'] ? this.$refs['form'].resetFields() : '';
        },
        getTool() {
            this.$store.dispatch('manage/public', {
                query: {
                    pageNum: 1,
                    pageSize: 9999
                },
                requestInterfaceName: 'getToolList'
            }).then(res=>{
                if(res.code == 0) {
                    this.options = res.data.records;
                }
            })
        },
        // 保存
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$store.dispatch(`manage/${this.mode == 'add' ? 'add' : 'edit'}`, {
                        vkey: this.vkey,
                        query: this.form,
                        requestInterfaceName: this.mode == 'add' ? 'addMostpopular' : 'editMostpopular',
                        listInterfaceName: 'getMostpopularList'
                    }).then(res=>{
                        if(res.code == 0) {
                            this.$message.success(this.mode == 'add' ? '添加成功' : '修改成功');
                        } else {
                            this.$message.error(res.message);
                        }
                        this.closeDialog();
                    })
                }
            });
        },
        closeDialog() {
            this.dialogVisible = false;
            this.$refs['form'].resetFields();
        }
    }
};
</script>
<style lang="less" scoped>
.demo-ruleForm {
    /deep/ .el-upload {
        width: 120px;
        height: 120px;
        line-height: 124px;
    }
}
</style>